@font-face {
  font-family: "BoldExtended";
  src: local("BoldExtended"),
    url(./fonts/Bold-font/MonumentExtended-Ultrabold.otf) format("opentype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "extendedRegular";
  src: local("extendedRegular"),
    url(./fonts/Bold-font/MonumentExtended-Regular.otf) format("opentype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to bottom, #ffffff, #f1f7fe) !important;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9c9c9c;
  /* border-radius: 8px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000000;
}

:root {
  --swiper-navigation-size: 16px !important;
}
:root {
  --swiper-theme-color: #000000 !important;
}

.rotating-box {
  animation: rotateClockwise 30s linear infinite,
    rotateAntiClockwise 30s linear infinite; /* Adjust duration as needed */
}

@keyframes rotateClockwise {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes rotateAntiClockwise {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.certificate-crousal .react-multiple-carousel__arrow {
  background: transparent !important;
}

.certificate-crousal .react-multiple-carousel__arrow::before {
  color: black !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.certificate-crousal .react-multi-carousel-dot-list {
  position: relative !important;
  overflow: auto !important;
  width: 140px !important;
  margin: auto;
}

.certificate-crousal .react-multi-carousel-dot button {
  height: 8px !important;
  width: 8px !important;
  border-width: 1px !important;
}

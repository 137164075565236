.round-text {
  animation: rotateAndPause 12s linear infinite;
}

@keyframes rotateAndPause {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.round-flower {
  animation: rotateAndPause 12s linear infinite;
}

@keyframes rotateAndPause {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .main-image {
    width: 185px !important;
  }
  .rectangle-image {
    display: none !important;
  }
}

.custom-swal-container {
  z-index: 1400 !important;
}

.gradient-text {
  background: -webkit-linear-gradient(
    180deg,
    #ffda8d,
    #ffda8d,
    #e62a21,
    #e62a21,
    #8121a3,
    #8121a3
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

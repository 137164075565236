.sketch-picker {
    border-radius: 13px !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  }
  
  .sketch-picker div:first-child {
    border-radius: 13px !important;
  }
  
  .flexbox-fix:last-child {
    display: none !important;
  }
  
  .rotate-reload {
    animation: rotateAndPause 2s linear infinite;
  }
  
  @keyframes rotateAndPause {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.active-component {
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  border-bottom: 2px solid #000 !important;
  cursor: pointer !important;
}

.not-active-component {
  color: #97999d;
  font-size: 13px !important;
  font-weight: 400 !important ;
  text-transform: capitalize !important;
  cursor: pointer !important;
}


:root {
  --diceSize: 100px;
  --dotSize: calc(var(--diceSize) * 0.18);
}

h1 {
  text-align: center;
}

.main-container {
  /* max-width: 800px;
  margin: 0 auto; */
}

.main-dice-container {
  /* padding: 50px; */
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.dice-container {
  height: var(--diceSize);
  width: var(--diceSize);
  perspective: 1000px;
}

.dice {
  width: var(--diceSize);
  height: var(--diceSize);
  transform-style: preserve-3d;
  transition: all 200ms;
  &.face-1 {
    transform: rotateX(0);
  }
  &.face-2 {
    transform: rotateY(90deg);
  }
  &.face-3 {
    transform: rotateX(-90deg);
  }
  &.face-4 {
    transform: rotateX(90deg);
  }
  &.face-5 {
    transform: rotateY(-90deg);
  }
  &.face-6 {
    transform: rotateX(-180deg);
  }
}

.dot-container {
  display: grid;
  grid-template-columns: repeat(3, var(--dotSize));
  grid-template-rows: repeat(3, var(--dotSize));
  grid-gap: calc(var(--dotSize) / 4) calc(var(--dotSize) / 4);
  background-color: white;
}

.dot {
  background-color: black;
  border-radius: 50%;
}

.dice {
  [class^="face"] {
    position: absolute;
    width: var(--diceSize);
    height: var(--diceSize);
    outline: 2px solid #00bbff;
    outline-offset: -2px;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .face-1 {
    transform: translateX(0) translateY(0) translateZ(calc(var(--diceSize) / 2));
    .dot-container {
      .dot {
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;
      }
    }
  }

  .face-3 {
    transform: translateX(0) translateY(-50%) translateZ(0) rotateX(90deg);
    .dot-container {
      .dot:nth-child(1) {
        grid-column: 3 / span 1;
        grid-row: 1 / span 1;
      }
      .dot:nth-child(2) {
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;
      }
      .dot:nth-child(3) {
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
      }
    }
  }

  .face-2 {
    transform: translateX(-50%) translateY(0%) translateZ(0) rotateY(90deg);
    .dot-container {
      .dot:nth-child(1) {
        grid-column: 3 / span 1;
        grid-row: 1 / span 1;
      }
      .dot:nth-child(2) {
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
      }
    }
  }

  .face-4 {
    transform: translateX(0) translateY(50%) translateZ(0) rotateX(90deg);
    .dot-container {
      .dot:nth-child(1) {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
      }
      .dot:nth-child(2) {
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
      }
      .dot:nth-child(3) {
        grid-column: 3 / span 1;
        grid-row: 3 / span 1;
      }
      .dot:nth-child(4) {
        grid-column: 3 / span 1;
        grid-row: 1 / span 1;
      }
    }
  }

  .face-5 {
    transform: translateX(50%) translateY(0%) translateZ(0) rotateY(90deg);
    .dot-container {
      .dot:nth-child(1) {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
      }
      .dot:nth-child(2) {
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
      }
      .dot:nth-child(5) {
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;
      }
      .dot:nth-child(3) {
        grid-column: 3 / span 1;
        grid-row: 3 / span 1;
      }
      .dot:nth-child(4) {
        grid-column: 3 / span 1;
        grid-row: 1 / span 1;
      }
    }
  }

  .face-6 {
    transform: translateX(0) translateY(0)
      translateZ(calc(-1 * var(--diceSize) / 2));
    .dot-container {
      .dot:nth-child(1) {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
      }
      .dot:nth-child(2) {
        grid-column: 3 / span 1;
        grid-row: 3 / span 1;
      }
      .dot:nth-child(3) {
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
      }
      .dot:nth-child(4) {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
      }
      .dot:nth-child(5) {
        grid-column: 3 / span 1;
        grid-row: 1 / span 1;
      }
      .dot:nth-child(6) {
        grid-column: 3 / span 1;
        grid-row: 2 / span 1;
      }
    }
  }
}

.button-container {
  display: flex;
  justify-content: center;
}

.dan-btn {
  text-decoration: none;
  display: inline-block;
  padding: 8px 10px;
  border-radius: 3px;
  background-color: #dddddd;
  color: #444;
  cursor: pointer;
  &[disabled] {
    opacity: 0.4;
  }
}

.dan-btn--default {
  background-color: #dddddd;
  color: #444;
}

/* Add this CSS to vibrate the dice-number-container */
@keyframes vibrate {
  0%, 100% {
    transform: translate(0);
  }
  20%, 40%, 60%, 80% {
    transform: translate(-2px, -2px);
  }
  30%, 50%, 70%, 90% {
    transform: translate(2px, -2px);
  }
}


.dice-number-container {
  height: var(--diceSize);
  width: var(--diceSize);
  outline: 2px solid #00bbff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1;
}

.number-container-animate {
  animation: vibrate 0.5s ease infinite; /* Apply the animation */
}

.number {
  font-size: xx-large;
  font-weight: bold;
}

/* animation header text css */
.animated-text {
  font-size: 13px;
  font-weight: 500;
  margin-left: 3px;
  letter-spacing: 1px;
  background: linear-gradient(
    90deg,
    #ff00d2,
    #fed904,
    #00a2ff,
    #09f1b8,
    #ff00d2,
    #fed904,
    #00a2ff,
    #09f1b8
  );

  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-size: 300% 300%;
  animation: gradient 7s ease-in-out infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* animation buton css */

.animated-button {
  position: relative;
  overflow: hidden;
  transition: 0.6s;
}

.animated-button-bg {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  transition: 0.6s;
  /* z-index: 1350; */
}

.animated-button:hover .animated-button-bg {
  left: 0;
  transition: 0.6s;
}

/* .animated-button span {
  transition: 0.6s;
}

.animated-button:hover span {
  color: white;
  z-index: 999;
  position: absolute;
  left: 20%;
} */

/* .hover-icon {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  transition: 0.6s;
} */

/* .animated-button:hover .hover-icon {
  opacity: 1;
  left: 60%;
} */

/* loader screee csss  */
.custom-loader {
  position: absolute;
  width: 260px;
  height: 260px;
  border-radius: 50%;
  background: conic-gradient(rgba(82, 0, 0, 0) 44%, #dd2a7b);
  -webkit-mask: radial-gradient(
    farthest-side,
    #fffcfc00 calc(100% - 25px),
    #000 0
  );
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}

/* react-loadingg csss */

.loader {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  /* background: red; */
  z-index: 9999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* textareaa */

textarea:focus {
  outline: none; /* Remove default outline on focus */
  border: 1px solid "#bdb9b9"; /* Customize border style on focus */
}

/* nav togle class */
.active-tabbbb {
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  border-bottom: 2px solid #000 !important;
  cursor: pointer !important;
}

.not-active-tabbb {
  color: #97999d;
  font-size: 13px !important;
  font-weight: 400 !important ;
  text-transform: capitalize !important;
  cursor: pointer !important;
}

.a_link_support {
  text-decoration: none;
  color: inherit;
}

.loader-text {
  position: absolute;
  animation: fontSizeAnimation 1s ease infinite;
  font-weight: 700;
  font-family: "Roboto Flex";
}

@keyframes fontSizeAnimation {
  0%,
  100% {
    font-size: 80px;
  }
  50% {
    font-size: 60px;
  }
}

.rotate-wave {
  transform: rotate(90deg);
}

/* tik tok .loader */

.tiktok-loader {
  /* width: 80px;
  height: 40px; */
  position: relative;
  top: -22px;
}

.tiktok-loader::before,
.tiktok-loader::after {
  position: absolute;
  content: "";
  top: 6px;
  background-color: #ff465f;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  mix-blend-mode: multiply;
  animation: tiktok-loader-anim 1s linear infinite;
  /* border: 1px solid black; */
  border-width: thin;
  border-color: black;
}

.tiktok-loader::after {
  background-color: #4de8f4;
  animation-delay: 0.5s;
}

@keyframes tiktok-loader-anim {
  0%,
  100% {
    top: 6px;
    left: 0px;
    width: 25px;
    height: 25px;
    z-index: 0;
  }

  25% {
    top: 0px;
    height: 35px;
    width: 35px;
    z-index: 1;
    left: 20px;
  }

  50% {
    top: 6px;
    width: 25px;
    height: 25px;
    left: 48px;
  }

  75% {
    top: 8px;
    width: 25px;
    height: 25px;
    left: 26px;
  }
}

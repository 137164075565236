@keyframes flipHeads {
  0% {
    transform: rotateX(0) scale(1);
  }
  50% {
    transform: rotateX(3600deg) scale(1.3); /* Change to 3600deg for a complete flip */
  }
  100% {
    transform: rotateX(7200deg) scale(1); /* Change to 7200deg for a complete flip */
  }
}

@keyframes flipTails {
  0% {
    transform: rotateX(0) scale(1);
  }
  50% {
    transform: rotateX(3600deg) scale(1.3); /* Change to 3600deg for a complete flip */
  }
  100% {
    transform: rotateX(7200deg) scale(1); /* Change to 7200deg for a complete flip */
  }
}

#coin {
  position: relative;
  margin: 0 auto;
  width: 200px;
  height: 200px;
  transition: transform 10s ease-in-out; /* Reduced transition duration */
  transform-style: preserve-3d;
  cursor: pointer;
}

#coin div {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: inset 0 0 45px rgba(255, 255, 255, 0.3),
    0 12px 20px -10px rgba(0, 0, 0, 0.4);
}

.side-a {
  color: white;
  text-align: center;
}

.side-b {
  color: white;
  text-align: center;
}

#coin div {
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-a {
  z-index: 2;
}

.side-b {
  transform: rotateX(-180deg);
}

.coin.heads {
  animation: flipHeads 3s ease-out forwards; /* Adjusted animation duration */
}

.coin.tails {
  animation: flipTails 3s ease-out forwards; /* Adjusted animation duration */
}

.coin-doted-line {
  border: 2px dotted white;
  width: 80% !important;
  height: 80% !important;
}

.round-text {
    animation: rotateAndPause 12s linear infinite;
  }
  
  @keyframes rotateAndPause {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @media (max-width: 600px) {
    .main-image {
      width: 185px !important;
    }
    .rectangle-image {
      display: none !important;
    }
  }
  
  .custom-swal-container {
    z-index: 1400 !important;
  }
  
  .gradient-text {
    background: -webkit-linear-gradient(
      180deg,
      #ffda8d,
      #ffda8d,
      #e62a21,
      #e62a21,
      #8121a3,
      #8121a3
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  
  /* Card Animation */
  .social-card-container {
    transform: translate(0);
    transition: 0.5s ease !important;
    background-color: #ffffff;
  }
  
  .card-background {
    position: relative;
    border-radius: 15px;
  }
  
  .social-card-container:hover {
    transform: translate(7px, -7px);
  }
  
  .icon-normal {
    display: block;
  }
  
  .icon-color {
    display: none;
  }
  
  .social-card-container:hover .icon-normal {
    display: none;
  }
  
  .social-card-container:hover .icon-color {
    display: block;
  }
  
  .social-card-container:hover .first-arrow-1 {
    visibility: hidden;
  }
  